export const isMobile = () => {
  let viewportwidth;
    
  if (typeof window.innerWidth != 'undefined') {
    viewportwidth = window.innerWidth;
  } else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0) {
    viewportwidth = document.documentElement.clientWidth;
  } else {
    viewportwidth = document.getElementsByTagName('body')[0].clientWidth;
  }

  if(viewportwidth > 992) {
    return false;
  } else {
    return true;
  }
};

export const toggleElementClass = (el, selector) => {
  if(el.classList.contains(selector)) {
    el.classList.remove(selector);
  } else {
    el.classList.add(selector);
  }
};