import { isMobile } from '../helpers/helpers';
import { deferImages } from '../helpers/defer';
import { mask, submitForm } from '../components/form';

$(document).ready(function() {
  deferImages();
  mask();
  submitForm();

  $("a").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top - 100
      }, 800, function(){
        
      });
    } // End if
  });

  $('.lead-moment__close').on('click', function() {
    $('.lead-moment').removeClass('lead-moment--opened')
  });

  if(!isMobile()) {
    $.fn.isInViewport = function() {
      var elementTop = $(this).offset().top - 100;
      var elementBottom = elementTop + $(this).outerHeight() - 100;
  
      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();
  
      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;

    $(window).scroll(function() {
      didScroll = true;
    });

    setInterval(function() {
      if (didScroll) {
        var st = $(this).scrollTop();

        hasScrolled(st);
        didScroll = false;
      }
    }, 250);

    function hasScrolled(st) {    
      if ($('#intro').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#intro"]').parent().addClass('menu__item--active');
      } else if($('#plataforma').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#plataforma"]').parent().addClass('menu__item--active');
      } else if($('#operacao').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#operacao"]').parent().addClass('menu__item--active');
      } else if($('#marketing').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#marketing"]').parent().addClass('menu__item--active');
      } else if($('#inovacao').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#inovacao"]').parent().addClass('menu__item--active');
      } else if($('#projetos').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#projetos"]').parent().addClass('menu__item--active');
      } else if($('#contato').isInViewport()) {
        $('.menu__item').removeClass('menu__item--active');
        $('.menu__item a[href="#contato"]').parent().addClass('menu__item--active');
      } 

      if (Math.abs(lastScrollTop  -  st) <= delta) return;
  
      lastScrollTop = st;
    }
  }
});